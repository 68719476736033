:root {
    --themeColor: #078C61;
    --blue: #31D7EC;
    --yellow: #FBBC04;
    --white: #FFFFFF;
    --black: #272628;
    --gray: #767D87;
    --lightGreen: #E6F8EE;
    --lightText: #77CCB1;
    --bg: #E1E1E1;
    --bgColor: #F9F9F9;
    --textPalceholder: #D7D3CB;
}